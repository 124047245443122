interface column {
	SchemaId?: any;
	catalog?: any;
	column?: any;
	last_modified?: any;
	schema?: any;
	table?: any;
	tableId?: any;
	type?: any;
	uuid?: any;
	version?: any;
}
interface prestoTableColumnList {
	prestoTableColumnList: column[];
}
interface dbTable {
	catalog?: any;
	last_modified?: any;
	schema?: any;
	schemaId?: any;
	table?: any;
	uuid?: any;
	version?: any;
	columns?: column[];
}
interface prestoSchemaTableList {
	prestoSchemaTableList: dbTable[];
}
interface schema {
	schema?: any;
	catalog?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	connectionId?: any;
	tableList?: dbTable[];
}
interface prestoCatalogSchemaList {
	prestoCatalogSchemaList: schema[];
}
interface dbList {
	connectionId?: any;
	catalog?: any;
	dbType?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	numberOfSchema?: any;
	schemaList?: schema[];
	qubzDataWarehouse?: any;
	publicName?: any;
}
interface prestoData {
	prestoCatalogList: dbList[];
}

import {
	Component,
	OnInit,
	AfterViewInit,
	Output,
	ViewChild,
	EventEmitter,
	AfterContentChecked
} from "@angular/core";
import * as $ from "jquery";
import swal from "sweetalert2";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DataExplorerService } from "../../../services/data-explorer/data-explorer.service";
import { OrderPipe } from "ngx-order-pipe";
import { Subscription } from "rxjs";
import { MetadataService } from "../../../services/ace-editor/metadata.service";
import { VisualQueryBuilderService } from "../../../components/data-explore/visual-query-builder/visual-query-builder.service";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
	selector: "app-data-sources",
	templateUrl: "./data-sources.component.html",
	styleUrls: ["./data-sources.component.scss"]
})
export class DataSourcesComponent
	implements OnInit, AfterViewInit, AfterContentChecked
{
	load;
	showQubzType = false;
	showType = false;
	isFullscreen: boolean = false;
	show_pwd: boolean = true;
	form: FormGroup;
	selectedDbType;
	isActive: any[] = [];
	tableIsActive: any[] = [];
	columnIsActive: any[] = [];
	dbListArr: dbList[];
	popupName = "";
	isEdit = false;
	selectedConnectionId = null;
	showToggle: any[] = [];
	isQueryAvailable = true;
	qdwLoader;
	sourceLoader;
	users = [
		{ id: "iqsandbox", name: "IQSandbox" },
		//{ id: 'qubz', name: 'QUBZDW' },
		{ id: "hive2", name: "Hive" },
		// {id: 'denodo', name: 'Denodo'},
		// {id: 'synapse', name: 'Azure Synapse Analytics'},
		{ id: "mysql", name: "MySQL" },
		{ id: "redshift", name: "Amazon Redshift " },
		{ id: "sqlserver", name: "Microsoft SQL Server" },
		{ id: "postgresql", name: "PostgreSQL" },
		{ id: "oracle", name: "Oracle" },
		{ id: "memsql", name: "MemSQL" },
		// {id: 'db2', name: 'DB2'},
		{ id: "mongodb", name: "MongoDB" },
		{ id: "kafka", name: "Kafka" },
		{ id: "bigquery", name: "Google BigQuery" },
		{ id: "snowflake", name: "Snowflake" },
		{ id: "iq_warehouse", name: "IQWarehouse" }
		// { id: "salesforce", name: "Salesforce" } hiding for now. Will enable once we implement from backend.
	];
	dbTypes = [];
	dbImage = [
		{ type: "mysql", value: "../../../assets/images/mysql_ico.png" },
		{ type: "postgresql", value: "../../../assets/images/postgreysql_ico.png" },
		{ type: "redshift", value: "../../../assets/images/redshift_ico.png" },
		{ type: "sqlserver", value: "../../../assets/images/sqlserver_ico.png" },
		{ type: "oracle", value: "../../../assets/images/oracle_ico.png" },
		{ type: "hive2", value: "../../../assets/images/hive_ico.png" },
		{ type: "iq_warehouse", value: "../../../assets/images/warehouse_ico.png" },
		{ type: "memsql", value: "../../../assets/images/memsql_ico.png" },
		{ type: "mongodb", value: "../../../assets/images/mongo_ico.png" },
		{ type: "bigquery", value: "../../../assets/images/bigquery.png" },
		{ type: "kafka", value: "../../../assets/images/kafka.png" },
		{ type: "snowflake", value: "../../../assets/images/snoflake.png" }
		// { type: "salesforce", value: "../../../assets/images/salesforce.jpg" }  hiding for now. Will enable once we implement from backend.
	];
	result;
	order: string = "columnMetas";
	reverse: boolean = false;
	sortedCollection;
	limit: number = 5000;
	check: boolean = true;
	mainQuery;
	options;
	page: number = 1;
	status;
	error;
	startTime;
	cachedQueries = [];
	history: any;
	subscription: Subscription;
	queriesHistory: any;
	isShowquery: boolean = false;
	isShowquerydiv: boolean = false;
	queryView;
	catalogName;
	currentCatalog;
	currentSchema;
	currentTable;
	queryDescription: any;
	queryName: any;
	savedQueryList: any = [];
	loggedUser: any;
	savedQuerysubscription: Subscription;
	saveQury: any;
	showMatModal: boolean;
	tableNamePattern = "^[A-Za-z0-9_-]{1,100}$";
	showDatabaseNameInDropDown: boolean = true;
	showDatabaseNameInTextField: boolean = true;
	databaseList: any;
	selectedDb;
	selectedQw;
	selectedTable;
	selectedQwTable;
	loader;
	queries1 = [];
	currentQueries = [];
	indexTab;
	resultViewTab;
	curQuery: boolean;
	refresh;
	current: boolean;
	statusActive;
	loadResult;
	searchText = null;
	showViewDataTab: boolean = true;
	showColumnTab: boolean = true;
	loadViewData: any;
	searchColumnNameTxt = null;
	@Output() filterEvent: EventEmitter<any> = new EventEmitter();
	viewTableDetails: any;
	step1: boolean = true;
	step2: boolean = false;
	step3: boolean = false;
	step4: boolean = false;
	step5: boolean = false;
	step6: boolean = false;
	resultView;
	loadViewResult: any;
	viewDataCatalog;
	viewDataSchema;
	viewDataTable;
	viewDataError;
	queryViewData;
	resultViewStatus;
	resultViewTime;
	showViewDataMatModal: boolean;
	showViewDataDatabaseNameInDropDown: boolean = true;
	showviewDataDatabaseNameInTextField: boolean = true;
	statusList = [
		{ name: "All", value: "All" },
		{ name: "Executing", value: "Executing" },
		{ name: "Success", value: "Success" },
		{ name: "Failed", value: "Failed" }
	];
	listData;
	metainfo;
	dataList;
	metadata$ = this.service.getMetadata();
	tableName;
	requestorder: string = "requesterName";
	reqreverse: boolean = false;
	revokedResponse;
	noDataMsg: boolean = false;
	sourceType;
	qubzLicenseExpire: any;
	qubzLicenseExpireMsg: any;
	resetLoader: boolean;
	tableLoader: boolean;
	approvedRequest: any;
	showTable() {
		document.getElementById("show_table").classList.add("show_table");
		document.getElementById("show_graph").classList.remove("show_graph");
	}
	showGraph() {
		document.getElementById("show_graph").classList.add("show_graph");
		document.getElementById("show_table").classList.remove("show_table");
	}

	selectedCatalogObj = {
		catalogId: "",
		catalogName: "",
		catalogPublicName: "",
		isCompleted: false,
		processedBy: "",
		processedDate: "",
		processorId: "",
		requestId: "",
		reason: "",
		resolutionReason: "",
		requestedDate: "",
		requesterId: "",
		requesterName: "",
		status: "",
		requestTracker: []
	};

	catalogRequest;
	approvalList;
	revokeModal: boolean;
	revokeReason;
	isDisabled: boolean = true;
	loadRevoke;
	isPattern: boolean = false;
	isCatalogRequired: boolean = false;
	isSubmitEnabled: boolean = true;
	showUserAccess: boolean = true;
	searchtblTxt = null;
	noofSchema;

	constructor(
		private dataExplorerService: DataExplorerService,
		private orderPipe: OrderPipe,
		private service: MetadataService,
		private builderService: VisualQueryBuilderService,
		private router: Router
	) {
		this.sortedCollection = orderPipe.transform(this.result, "columnMetas");
	}
	ngOnDestroy() {
		this.dataExplorerService.setFormData(null);
	}
	ngOnInit() {
		//localStorage.setItem("resetLoader","false");
		// this.dataExplorerService.resetLoader =JSON.parse(localStorage.getItem("resetLoader"));
		this.resetLoader = this.dataExplorerService.resetLoader
		// this.sub = interval(5000).subscribe((x =>{
		// 	if(this.dataExplorerService.resetLoader===false){
		// 		this.sub.unsubscibe();
		// 		this.resetLoader = this.dataExplorerService.resetLoader
		// 	}
		// }));
		this.qubzLicenseExpire = JSON.parse(
			sessionStorage.getItem("qubzLicenseExpire")
		);
		this.qubzLicenseExpireMsg = JSON.parse(
			sessionStorage.getItem("qubzLicenseExpireMsg")
		);
		this.isShowquerydiv = false;
		this.dbList();
		this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
		this.searchText = "All";
		localStorage.setItem("clearVal", "");
		localStorage.setItem("toSecond", "");
		localStorage.setItem("prevBtn", "");
		this.clearVal();
		localStorage.setItem("resetSource", "");
		localStorage.setItem("resetKafkaForm", "");
		localStorage.setItem("resetJsonForm", "");
		localStorage.setItem("catalogResponse", "");
		localStorage.setItem("sourceResponse", "");
		localStorage.setItem("newcatalogResponse", "");
		localStorage.setItem("resetCatalog", "");
		localStorage.setItem("activeStatus", "");
		localStorage.setItem("catalogObj", "");
		localStorage.setItem("dbTypelist", "");
		localStorage.setItem("isEdit", "");
		localStorage.setItem("sourceType", "");
		localStorage.setItem("connectionId", "");
		localStorage.setItem("nofSchema", "");
	}

	getData(param) {
		this.resultView = {};
		this.resultViewStatus = "Executing";
		this.resultViewTime = new Date();
		this.loadViewResult = true;
		this.queryViewData =
			"select * from " +
			param.catalogName +
			"." +
			param.schema +
			"." +
			param.table;
		$(".result-c").addClass("showresult");
		this.dataExplorerService.getPrestoResult(param).subscribe(
			(data: string) => {
				this.resultView = data;
				this.resultViewStatus = "Success";
				this.isQueryAvailable = false;
				if (this.resultView.SQLResponse) {
					this.loadViewResult = false;
				} else {
					this.loadViewResult = false;
				}
			},
			error => {
				this.viewDataError = error;
				this.resultViewStatus = "Failed";
				this.loadViewResult = false;
			}
		);
	}

	hasRole(roles) {
		let user = JSON.parse(sessionStorage.getItem("loggedUser"));
		for (let role of user.authorities) {
			if (role.authority == roles) return "admin";
		}
		return "user";
	}

	dbList() {
		this.load = true;
		this.dataExplorerService.getConnectionsList().subscribe(
			(data: prestoData) => {
				this.dbListArr = data.prestoCatalogList;
				for (let row of this.dbListArr) {
					if (row.qubzDataWarehouse) {
						this.showSchemaList(row.connectionId, row.catalog, row.publicName);
						this.showToggle[row.catalog] = !this.showToggle[row.catalog];
					}
				}
				this.load = false;
			},
			error => {
				this.load = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}
	showSchemaList(connectionId, catalog, publicName) {
		if (catalog == "iq_warehouse") this.qdwLoader = true;
		else this.sourceLoader = true;
		this.dataExplorerService.getSchemaList(connectionId).subscribe(
			(data: prestoCatalogSchemaList) => {
				if (this.dbListArr)
					this.dbListArr.map(item => {
						if (item.connectionId === connectionId) {
							if (!item.schemaList)
								item.schemaList = data.prestoCatalogSchemaList;
						}
					});
				this.qdwLoader = false;
				this.sourceLoader = false;
			},
			error => {
				this.qdwLoader = false;
				this.sourceLoader = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}

	showSchemaListClicked(connectionId, catalog, publicName) {
		if (this.showToggle[catalog] == undefined) {
			this.showUserAccess = false;
			this.step3 = true;
			this.selectedCatalogObj.catalogPublicName = publicName;
			this.showViewDataTab = true;
			this.showRequest(catalog, connectionId);
			this.selectedCatalogObj.catalogName = catalog;
		} else if (this.showToggle[catalog] == true) {
			this.selectedCatalogObj.catalogPublicName = undefined;
			this.showUserAccess = true;
			this.showViewDataTab = true;
			this.noDataMsg = false;
		} else if (this.showToggle[catalog] == false) {
			this.showUserAccess = false;
			this.step3 = true;
			this.selectedCatalogObj.catalogPublicName = publicName;
			this.selectedCatalogObj.catalogName = catalog;
			this.showViewDataTab = true;
			this.showRequest(catalog, connectionId);
		}

		if (catalog == "iq_warehouse") this.qdwLoader = true;
		else this.sourceLoader = true;
		this.dataExplorerService.getSchemaList(connectionId).subscribe(
			(data: prestoCatalogSchemaList) => {
				if (this.dbListArr)
					this.dbListArr.map(item => {
						if (item.connectionId === connectionId) {
							if (!item.schemaList)
								item.schemaList = data.prestoCatalogSchemaList;
						}
					});
				this.qdwLoader = false;
				this.sourceLoader = false;
			},
			error => {
				this.qdwLoader = false;
				this.sourceLoader = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}
	schemaClick(schema, currentCatalogId, ev) {
		ev.stopPropagation();
		let currentSchemaId = schema.uuid;
		if (schema.catalog == "iq_warehouse") this.qdwLoader = true;
		else this.sourceLoader = true;
		this.dataExplorerService.getTableListOnSchema(currentSchemaId).subscribe(
			(data: prestoSchemaTableList) => {
				this.dbListArr.map(item => {
					if (item.connectionId === currentCatalogId) {
						item.schemaList.map(element => {
							if (element.uuid === currentSchemaId) {
								if (!element.tableList)
									element.tableList = data.prestoSchemaTableList;
							}
						});
					}
				});
				this.qdwLoader = false;
				this.sourceLoader = false;
			},
			error => {
				this.qdwLoader = false;
				this.sourceLoader = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}
	tableClick(
		check,
		currentCatalog,
		currentSchema,
		currentTable,
		catalogId,
		schemaId,
		tableName,
		currentTableId,
		ev
	) {
		ev.stopPropagation();
		if (check == "viewData") {
			this.tableName = tableName;
		}
		this.currentCatalog = currentCatalog;
		this.currentSchema = currentSchema;
		this.currentTable = currentTable;
		this.dataExplorerService.getColumnListOnTable(currentTableId).subscribe(
			(data: prestoTableColumnList) => {
				this.dbListArr.map(item => {
					if (item.connectionId === catalogId) {
						item.schemaList.map(element => {
							if (element.uuid === schemaId) {
								element.tableList.map(ele => {
									if (ele.uuid === currentTableId) {
										ele.columns = data.prestoTableColumnList;
									}
								});
							}
						});
					}
				});
				this.loader = false;
			},
			error => {
				this.loader = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}

	columnClick(ev) {
		ev.stopPropagation();
	}
	loadDbImage(type, warehouse) {
		let img;
		if (!warehouse) {
			this.dbImage.filter(item => {
				if (item.type === type) img = item.value;
			});
		} else {
			img = "../../../assets/images/iqsandbox_icon.svg";
		}

		return img;
	}
	createDataSource() {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			this.noDataMsg = true;
			this.showUserAccess = true;
			this.isEdit = false;
			localStorage.setItem("isEdit", JSON.stringify(this.isEdit));
			this.checkWarehouseAvailablity();
			this.router.navigate(["/data-source/create-data-source"]);
		}
	}
	deleteDatasourceConnection(currentCatalog, currentConnectionId, ev) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			ev.stopPropagation();
		swal({
			type: "warning",
			title:
				"Do you want to proceed with deletion of data source for the catalog: " +
				currentCatalog.toUpperCase() +
				" ?",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(
			status => {
				if (status.value === true) {
					this.load = true;
					let param;
					param = {
						connectionId: currentConnectionId
					};
					this.dataExplorerService.deleteConnectionDetails(param).subscribe(
						data => {
							let res = <any>{};
							res = data;
							this.dbList();
							this.load = false;
							this.showToggle = [];
							swal(
								"Success!",
								"Datasource connection deleted successfully",
								"success"
							);
						},
						error => {
							this.load = false;
							swal("Ooops", error.error.msg, "error");
						}
					);
				}
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
		}
	}

	fetchConnectionDataForEdit(currentConnectionId, sourceType, noofSchema) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			this.showUserAccess = true;
			this.noDataMsg = true;
			this.isEdit = true;
			localStorage.setItem("isEdit", JSON.stringify(this.isEdit));
			this.sourceType = sourceType;
			this.noofSchema = noofSchema;
			localStorage.setItem("nofSchema", this.noofSchema);
			localStorage.setItem("sourceType", sourceType);
			localStorage.setItem("connectionId", currentConnectionId);
			this.router.navigate(["/data-source/edit-data-source"]);
		}
	}

	checkWarehouseAvailablity() {
		this.dbTypes = [];
		if (this.dbListArr.find(({ catalog }) => catalog == "iq_warehouse")) {
			for (let param of this.users) {
				if (param.id != "iq_warehouse" || this.isEdit)
					this.dbTypes = [...this.dbTypes, param];
			}
		} else {
			for (let param of this.users) {
				this.dbTypes = [...this.dbTypes, param];
			}
		}
		localStorage.setItem("dbTypelist", JSON.stringify(this.dbTypes));
	}
	ngAfterViewInit() {
		$(document).ready(function () {
			var contenth = $(window).height() - 110;
			var sidebarh = $(window).height() - 111;
			$(".pagec").css("height", contenth);
			$(".sidebar-wrapper").css("height", sidebarh);

			function treeheight() {
				var pageheight_p = $(".pagec").height() - 180;
				var pageheight = pageheight_p * 0.5;
				$(".tabletree_parent").css("height", pageheight_p);
			}
			setTimeout(treeheight, 2);
		});
		$(window).resize(function () {
			function treeheight() {
				var pageheight_p = $(".pagec").height() - 180;
				var pageheight = pageheight_p * 0.5;
				$(".tabletree_parent").css("height", pageheight_p);
			}
			setTimeout(treeheight, 2);
		});
		$(".submitbtn").click(function () {
			$(".result-c").addClass("showresult");
		});
		$(".clicktable").click(function () {
			$(".tab-pane").removeClass("active");
			$(".tabitem").removeClass("active");
			function showtab() {
				$("#tabfour").addClass("active");
				$(".datalink").addClass("active");
			}
			setTimeout(showtab, 2);
		});
		$(".datasourcelink").click(function () {
			$(".tab-pane").removeClass("active");
			$(".tabitem").removeClass("active");
			function showdatalink() {
				$("#tabfive").addClass("active");
				$(".datasources").addClass("active");
			}
			setTimeout(showdatalink, 2);
		});
	}

	resetSingleCatalog(catalogName, connectionId) {
		if (catalogName == "iq_warehouse") this.qdwLoader = true;
		else {this.dataExplorerService.resetLoader = true;
			this.resetLoader = this.dataExplorerService.resetLoader
		}
		this.dataExplorerService.singleCatalogRefresh(connectionId).subscribe(
			data => {
				this.dataExplorerService.resetLoader = false;
				this.resetLoader = this.dataExplorerService.resetLoader
				this.qdwLoader = false;
				this.dbList();
				this.showToggle = [];
				this.tableName = undefined;
				swal("Success!", catalogName + " is successfully refreshed", "success");
			},
			error => {
				this.dataExplorerService.resetLoader = false;
				this.resetLoader = this.dataExplorerService.resetLoader
				this.qdwLoader = false;
				swal("Oops...", "Some error occured", "error");
			}
		);
	}
	//material view ends

	/*
	 *Delete Qubz datawarehouse database
	 */
	deleteQwDatabase(schema) {
		swal({
			type: "warning",
			title:
				"Are you sure you want delete " + schema.schema.toUpperCase() + "?",
			text: "All tables associated with this database will be permanently deleted",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(status => {
			if (status.value == true) {
				this.load = true;
				this.dataExplorerService.deleteDatabase(schema.uuid).subscribe(
					data => {
						this.load = false;
						this.dbList();
						this.showToggle = [];
						swal(
							"Success!",
							schema.schema + " is successfully removed",
							"success"
						);
					},
					error => {
						this.load = false;
						swal("Oops...", "Some error occured", "error");
					}
				);
			}
		});
	}
	/*
	 *Delete table from Qubz datawarehouse database
	 */
	deleteQwDatabaseTable(table) {
		swal({
			type: "warning",
			title: "Are you sure you want delete " + table.table.toUpperCase() + "?",
			text: "All records will be permanently deleted",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(status => {
			if (status.value == true) {
				this.load = true;
				this.dataExplorerService.deleteTable(table.uuid).subscribe(
					data => {
						this.load = false;
						this.dbList();
						this.showToggle = [];
						swal(
							"Success!",
							table.table + " is successfully removed",
							"success"
						);
					},
					error => {
						this.load = false;
						swal("Oops...", "Some error occured", "error");
					}
				);
			}
		});
	}
	searchFilter(filter) {
		this.filterEvent.emit(filter);
	}

	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}
		this.order = value;
	}

	resetMetadata() {
		this.sourceLoader = true;
		this.dataExplorerService.resetMetadata().subscribe(
			data => {
				this.sourceLoader = false;
				this.dbList();
				swal("Success!", "Metadata is successfully refreshed", "success");
			},
			error => {
				this.sourceLoader = false;
				swal("Oops...", "Some error occured", "error");
			}
		);
	}

	showViewData(viewDataCatalog, viewDataSchema, viewDataTable, viewTableId) {
		if (this.result == null || this.result.SQLResponse == null) {
			this.resultViewTab = true;
		}
		this.showUserAccess = true;
		this.step1 = true;
		this.step2 = false;
		this.step3 = false;
		this.step4 = false;
		this.showViewDataTab = false;
		this.viewDataCatalog = viewDataCatalog;
		this.viewDataSchema = viewDataSchema;
		this.viewDataTable = viewDataTable;

		let param = {
			catalogName: this.viewDataCatalog,
			schema: this.viewDataSchema,
			table: this.viewDataTable
		};
		this.loadViewData = true;
		this.getData(param);
		this.dataExplorerService.getColumnListOnTable(viewTableId).subscribe(
			(data: prestoTableColumnList) => {
				this.viewTableDetails = data.prestoTableColumnList;
				this.loadViewData = false;
			},
			error => {
				this.loadViewData = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}

	setTab(nm) {
		if (nm == "result") {
			this.step1 = true;
			this.step2 = false;
			this.step3 = false;
			this.step4 = false;
		} else if (nm == "tableDtls") {
			this.step1 = false;
			this.step2 = true;
			this.step3 = false;
			this.step4 = false;
		} else {
			this.step1 = false;
			this.step2 = false;
			this.step3 = false;
			this.step4 = true;
		}
	}

	clearVal() {
		this.builderService.getCatalog = undefined;
		this.builderService.getCol = undefined;
		this.builderService.getSchema = undefined;
		this.builderService.getTable = undefined;
		this.builderService.getmainQuery = undefined;
		this.builderService.join = undefined;
		this.builderService.result = undefined;
		this.builderService.tableList = undefined;
		this.builderService.tableList = undefined;
		this.builderService.filters = undefined;
		this.builderService.groupBy = undefined;
		this.builderService.sortBy = undefined;
		this.builderService.limits = undefined;
		this.builderService.multiColumns = undefined;
		this.builderService.colListing = undefined;
		this.builderService.tables = [];
		this.builderService.fromTable = [];
		this.builderService.fromField = [];
		this.builderService.fromselectedTable = undefined;
		this.builderService.toTable = undefined;
		this.builderService.toField = undefined;
		this.builderService.toselectedTable = undefined;
		this.builderService.selectedField = undefined;
		this.builderService.titleList = [];
		this.builderService.selectedTableCols = [];
		this.builderService.newselectedTableCols = undefined;
		this.builderService.castArray = undefined;
		this.builderService.selectedElementDtls = [];
		this.builderService.selectedTableArr = [];
		this.builderService.selectedTableSort = undefined;
		this.builderService.colListingArray = [];
		this.builderService.selectedJoinCols = [];
		this.builderService.newSelectedJoinCols;
		this.builderService.comboArray = [];
		this.builderService.noColumnerrorMsg = undefined;
		this.builderService.selectSortCols = undefined;
		this.builderService.selectSortColsArr = [];
		this.builderService.selectSortColsJoin = undefined;
		this.builderService.selectSortColsJoinArr = [];
		this.builderService.selectSortColsAll = undefined;
		this.builderService.selectSortColArrAll = [];
		this.builderService.selectSortColsJoinAll = undefined;
		this.builderService.selectSortColsJoinArrAll = [];
		this.builderService.countAll = undefined;
		this.builderService.analyzerQueryObj = undefined;
		this.builderService.removeerrMsg = undefined;
	}

	getcatalogRequestList(id) {
		this.tableLoader=true;
		this.dataExplorerService
			.getCatalogRequestByCatalogId(0, 10, id, "APPROVED")
			.subscribe(
				data => {
					this.catalogRequest = data;
					this.approvedRequest = this.catalogRequest.page.content.filter(item =>item.status=="APPROVED")
					this.tableLoader=false;
				},
				error => {
					swal("Failed to load Data!", error.error.msg, "error");
					this.tableLoader=false
				}
			);
	}

	setreqOrder(value: string) {
		if (this.requestorder === value) {
			this.reqreverse = !this.reqreverse;
		}
		this.requestorder = value;
	}

	revoke(c) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			this.selectedCatalogObj = c;
		this.revokeReason = "";
		this.isDisabled = true;
		swal({
			type: "warning",
			title: "Are you sure you want to revoke access?",
			text: "All information associated to this request  will be cleared",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(
			status => {
				if (status.value === true) {
					this.openRevoke();
				} else {
					this.loadRevoke = false;
				}
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
		}
	}

	showRequest(catalog, connectionId) {
		if (
			this.showToggle[catalog] == undefined ||
			this.showToggle[catalog] == false
		) {
			this.getcatalogRequestList(connectionId);
		}

		if (this.showToggle[catalog] == true) {
			if (this.catalogRequest !== undefined) {
				this.catalogRequest.page.content = [];
			}
		}
	}

	openRevoke() {
		this.revokeModal = true;
	}
	closeRevoke() {
		this.revokeModal = false;
		this.revokeReason = "";
		this.isDisabled = true;
		this.checkVal();
	}

	submit() {
		let status;
		if (this.selectedCatalogObj.status) {
			status = "REVOKED";
		}

		let requestedDate =
			moment(this.selectedCatalogObj.requestedDate).format(
				"YYYY-MM-DDTHH:mm:ss"
			) + "Z";

		let processDate =
			moment(this.selectedCatalogObj.processedDate).format(
				"YYYY-MM-DDTHH:mm:ss"
			) + "Z";

		const param = {
			catalogId: this.selectedCatalogObj.catalogId,
			catalogName: this.selectedCatalogObj.catalogName,
			catalogPublicName: this.selectedCatalogObj.catalogPublicName,
			isCompleted: this.selectedCatalogObj.isCompleted,
			processedBy: this.selectedCatalogObj.processedBy,
			processedDate: processDate,
			processorId: this.selectedCatalogObj.processorId,
			reason: this.selectedCatalogObj.reason,
			resolutionReason: this.selectedCatalogObj.resolutionReason,
			requestId: this.selectedCatalogObj.requestId,
			requestedDate: requestedDate,
			requesterId: this.selectedCatalogObj.requesterId,
			requesterName: this.selectedCatalogObj.requesterName,
			status: status,
			requestTracker: this.selectedCatalogObj.requestTracker
		};

		this.loadRevoke = true;
		this.dataExplorerService.updateCatalogRequest(param).subscribe(
			data => {
				swal(
					"Success!",
					"User request access has been revoked",
					"success"
				).then(status => {
					if (status.value == true) {
						this.revokeModal = false;
						this.revokedResponse = data;
						this.loadRevoke = false;
						this.getcatalogRequestList(this.selectedCatalogObj.catalogId);
					}
				});
			},
			error => {
				this.loadRevoke = false;
				swal("Ooops", error.error.msg, "error");
			}
		);
	}

	checkVal() {
		if (this.revokeReason !== "") {
			this.selectedCatalogObj.resolutionReason = this.revokeReason;
			this.isDisabled = false;
		} else {
			this.isDisabled = true;
			this.selectedCatalogObj.resolutionReason = "";
		}
	}

	ngAfterContentChecked(): void {
		if (
			localStorage.getItem("catalogResponse") !== "" &&
			localStorage.getItem("catalogResponse") !== null
		) {
			this.checkWarehouseAvailablity();
		}
	}
	refreshMetaData(){
		swal(
			{
			  type:"warning",
			  title:"Are you sure you want to refresh all data sources?",
			  showCancelButton:true, confirmButtonText: "Yes",
			  cancelButtonText: 'No'
			}
			).then((status)=>{
				if(status.value == true) {
					this.dataExplorerService.resetLoader=true;
					this.resetLoader = this.dataExplorerService.resetLoader
					//localStorage.setItem("resetLoader","true");
					this.dataExplorerService.resetMetaData().subscribe(response =>{
					this.dbList();
					this.dataExplorerService.resetLoader=false;
					this.resetLoader = this.dataExplorerService.resetLoader
					swal("Success","All DataSources are refreshed");
					},error=>{
					this.dataExplorerService.resetLoader=false;
					this.resetLoader = this.dataExplorerService.resetLoader
					})
				}
			})
	}
}
